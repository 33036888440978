import { formatCurrency } from '~/lib/utils';
import type { CarRentFeature, CartListItem, PriceTuple } from '~/types';
import { parseISO, isAfter, isToday } from 'date-fns';

export const sanitizeSumItems = (items: CarRentFeature[]): CartListItem[] => {
  const result: CartListItem[] = [];
  for (const feature of items) {
    for (const option of feature.options) {
      if ((option?.qty || 0) < 1) {
        continue;
      }

      result.push({
        label: Number(option?.qty) > 1 ? `${option.qty}x ${option.label}` : option.label,
        totalPrice: (option?.qty || 0) * (option?.unitPrice || 0),
      });
    }
  }

  return result;
};

export const sumCartListItems = (list: CartListItem[], defaultValue = 0) => {
  return list.reduce((acc: number, item) => {
    acc += item.totalPrice || 0;

    return acc;
  }, defaultValue);
};

export const buildCheapestPrice = (prices: PriceTuple[]) => {
  const cheapestPrice: PriceTuple = prices.reduce((cheapest, current) => {
    return (current.price.value < cheapest.price.value) ? current : cheapest;
  });

  return formatCurrency(cheapestPrice.price.value) + '/' + cheapestPrice.price.periodicity;
};

export const extractTextInterpolation = (text: string) => {
  if (!text) return null;
  const regex = /{{(.*?)}}/;
  const match = text.match(regex);
  const interpolated = match?.[1] || false;
  const rest = match
    ? text.split(match?.[0])
    : [text];

  return {
    interpolated: interpolated && interpolated.trim(),
    rest,
  };
};

export const isExpiredDate = (dateString: string) => {
  const parsedDate = parseISO(dateString);
  // Check if the date is today or after today
  if (isAfter(parsedDate, new Date()) || isToday(parsedDate)) {
    return false; // The date is valid
  } else {
    return true; // The date is expired (not valid)
  }
};
